import { Button, Card, CardContent, Grid, TextField, Typography, Box } from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { format, isToday, isYesterday, parseISO } from "date-fns";
import { IoMdSend } from "react-icons/io";
import Divider from "@mui/material/Divider";
import { socket } from "../../../../socket";

const ChatHistory = ({ selectedUser, setNewMessage, setSeenMessageCalled, newMessage }) => {
  const page = 1;
  const perPage = 100000;
  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
  const [chatHistory, setChatHistory] = useState([]);
  const [message, setMessage] = useState("");
  const [roomId, setRoomId] = useState(null);
  const [sendMessageCalled, setSendMessageCalled] = useState(false);
  const chatContainerRef = useRef(null);
  const senderType = 1;
  const [metadata, setMetadata] = useState({});

  useEffect(() => {
    if (selectedUser && typeof selectedUser === "object") {
      fetchChatList();

      // Create Room for Customer Support
      socket.emit("createRoomForCustomerSupport", {
        senderId: 1,
        receiverId: selectedUser?.id
      });
    }

    // Listen for new messages - ensure this effect runs only once
    const handleNewMessage = (data) => {
      // console.log("newMessageForCustomerSupport listen at admin panel side..");
      // console.log(selectedUser?.id, "selectedUser");
      // console.log(data?.senderId, "senderUser");
      setNewMessage(data);
      const messageDiv = document.querySelector(`[data-sender="${data.senderId}"]`);
      if (selectedUser?.id == data?.senderId) {
        const firstMessage = messageDiv.querySelector("p");
        if (firstMessage) {
          firstMessage.textContent = data.message;
          setChatHistory((prev) => [...prev, data]);
        }
      }
    };

    socket.on("newMessageForCustomerSupport", handleNewMessage);

    // Cleanup the listener on unmount
    return () => {
      socket.off("newMessageForCustomerSupport", handleNewMessage);
    };
  }, [selectedUser, sendMessageCalled, newMessage]);

  const fetchChatList = async (currentPage = 1) => {
    try {
      // console.log("called the fetchChatList...");
      const headers = {
        "Content-Type": "application/json",
        "x-access-token": `${localStorage.getItem("token")}`
      };
      const userId = selectedUser?.id;
      const response = await axios.post(
        `${apiBaseURL}/api/v1/admin/chatdetails`,
        { page: currentPage, perPage, userId },
        { headers }
      );
      if (response.data) {
        const { chats, meta } = response.data.data;
        setMetadata(response.data.meta);
        setChatHistory(chats);
        // console.log(selectedUser, "selectedUser");
        // console.log(selectedUser?.roomId, "selectedUser?.roomId");
        setRoomId(selectedUser?.roomId);
        socket.emit("readMessageForCustomerSupport", {
          receiverId: userId,
          senderId: 1,
          chatId: 0,
          senderType: 1
        });
        setSeenMessageCalled({
          receiverId: userId,
          senderId: 1,
          chatId: 0
        });
      }
    } catch (error) {
      console.error("Error fetching chat list:", error);
    }
  };
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }

    const chatContainer = chatContainerRef.current;

    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [chatHistory]);

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop } = chatContainerRef.current;
      if (scrollTop === 0) {
        if (metadata.currentPage !== metadata.lastPage) {
          fetchChatList(metadata.currentPage + 1);
        }
      }
    }
  };

  const handleSendMessage = async () => {
    // console.log(message, "message");
    // console.log(roomId, "roomId");
    if (!message.trim() || !roomId) return;
    // console.log("called handleSendMessage...");
    const newMessage = {
      senderId: 1,
      receiverId: selectedUser?.id,
      message,
      roomId,
      senderType
    };

    // setChatHistory([...chatHistory, { ...newMessage, senderType: 1 }]);
    setMessage("");

    // Emit the message via socket
    socket.emit("sendMessageForCustomerSupport", newMessage);
    setNewMessage(newMessage);
    setSendMessageCalled(!sendMessageCalled);
  };

  const groupMessagesByDate = (messages) => {
    return messages.reduce((acc, message) => {
      const messageDate = new Date(message.sendAt * 1000);
      let dateLabel = format(messageDate, "yyyy-MM-dd");

      if (isToday(messageDate)) {
        dateLabel = "Today";
      } else if (isYesterday(messageDate)) {
        dateLabel = "Yesterday";
      } else {
        dateLabel = format(messageDate, "dd MMM yyyy"); // e.g., "31 Jan 2025"
      }

      if (!acc[dateLabel]) {
        acc[dateLabel] = [];
      }

      acc[dateLabel].push(message);
      return acc;
    }, {});
  };
  // console.log(selectedUser, "selectedUser");
  // console.log(chatHistory, "chatHistory");
  const groupedMessages = groupMessagesByDate(chatHistory);

  return (
    <Grid item md={8} lg={7} xl={8}>
      {chatHistory.length ? (
        <Card sx={{ height: 640 }}>
          <CardContent>
            <Typography variant="h6">
              {selectedUser != null
                ? `${selectedUser?.firstName} ${
                    selectedUser?.lastName ? selectedUser?.lastName : ""
                  }`
                : "Chat Section"}
            </Typography>
            <Divider sx={{ bgcolor: "secondary.light" }} />
            <Box
              ref={chatContainerRef}
              sx={{
                height: "400px",
                overflowY: "auto",
                display: "flex",
                flexDirection: "column",
                gap: 2,
                padding: 2,
                backgroundColor: "#FFFFFF",
                borderRadius: 2
              }}
            >
              {Object.keys(groupedMessages).map((dateLabel) => (
                <React.Fragment key={dateLabel}>
                  {/* Date Separator */}
                  <Typography
                    variant="subtitle2"
                    sx={{
                      textAlign: "center",
                      color: "#555",
                      fontWeight: "bold",
                      margin: "10px 0"
                    }}
                  >
                    {dateLabel}
                  </Typography>

                  {/* Messages for the Date */}
                  {groupedMessages[dateLabel].map((chat) => (
                    <Box
                      key={chat.id}
                      sx={{
                        alignSelf: chat.senderType === 2 ? "flex-start" : "flex-end",
                        backgroundColor: chat.senderType === 2 ? "#808080" : "#F44F2A",
                        color: "#000",
                        padding: "10px",
                        borderRadius: "10px",
                        maxWidth: "60%"
                      }}
                    >
                      <Typography sx={{ fontSize: "16px" }}>{chat.message}</Typography>
                      <Typography variant="body2" sx={{ fontSize: "10px", color: "#000000" }}>
                        {new Date(chat.sendAt * 1000).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit"
                        })}
                      </Typography>
                    </Box>
                  ))}
                </React.Fragment>
              ))}
            </Box>

            <TextField
              fullWidth
              multiline
              rows={2}
              placeholder="Type your message..."
              variant="outlined"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              style={{ margin: "85px 0" }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (e.shiftKey) {
                    // Allow newline (multiline behavior)
                    return;
                  }
                  // Prevent default enter behavior and send the message
                  e.preventDefault();
                  handleSendMessage();
                }
              }}
              InputProps={{
                endAdornment: (
                  <IoMdSend
                    style={{
                      height: "24px", // Increase height
                      width: "24px", // Increase width
                      cursor: "pointer", // Add pointer to indicate clickability
                      color: "#1976d2",
                      margin: "10px" // Optional: change color
                    }}
                    onClick={handleSendMessage}
                  />
                )
              }}
            />
          </CardContent>
        </Card>
      ) : (
        <Card sx={{ height: 640 }}>
          <CardContent
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: 5
            }}
          >
            <Box
              component="img"
              alt="The house from the offer."
              src={process.env.PUBLIC_URL + "/chat-screen.png"}
            />
            <Typography variant="h3">Get Started</Typography>
            <Typography variant="h5">Pick a user and start a conversation!</Typography>
          </CardContent>
        </Card>
      )}
    </Grid>
  );
};

export default ChatHistory;
