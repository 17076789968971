import { Avatar, Badge, Card, CardContent, Grid, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { socket } from "../../../../socket";

const ChatList = ({
  setSelectedUser,
  newMessage,
  seenMessageCalled,
  newMessageCame,
  selectedUser
}) => {
  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
  // console.log(newMessageCame, "newMessageCame");
  // console.log(newMessage, "newMessage");
  const [userList, setUserList] = useState([]);
  const [emitCalled, setEmitCalled] = useState(false);

  useEffect(() => {
    const fetchChatList = async () => {
      const headers = {
        "Content-Type": "application/json",
        "x-access-token": `${localStorage.getItem("token")}`
      };
      try {
        const response = await axios.get(`${apiBaseURL}/api/v1/admin/chatlist`, {
          headers: headers
        });
        if (response.data) {
          let { chatList } = response.data.data;
          // console.log(chatList, "chatList");
          //Join room for all the users
          chatList.map((user, index) => {
            socket.emit("createRoomForCustomerSupport", {
              senderId: 1,
              receiverId: user?.id,
              senderType: 1
            });
          });
          // socket.on("roomConnectedForCustomerSupport", ({ roomId }) => {
          // });
          setUserList(chatList);
        }
      } catch (error) {
        console.error("Error fetching chat list:", error);
      }
    };
    // console.log("chatlist updated at new message...");
    fetchChatList();
  }, [newMessage, emitCalled, seenMessageCalled, newMessageCame]);

  const getTimeDifference = (sendAt) => {
    const now = Math.floor(Date.now() / 1000);
    const diff = now - sendAt;
    if (diff < 60) return "Just now";
    if (diff < 3600) return `${Math.floor(diff / 60)} mins ago`;
    if (diff < 86400) return `${Math.floor(diff / 3600)} hours ago`;
    return `${Math.floor(diff / 86400)} days ago`;
  };

  socket.on("firstTimeMessage", (data) => {
    // console.log("called firstTimeMessage at chat side...");
    // console.log(data, "data");
    if (typeof data == "object") {
      setEmitCalled(!emitCalled);
    }
  });

  const truncateMessage = (message) => {
    return message?.length > 15 ? message.substring(0, 12) + "..." : message;
  };

  return (
    <Grid item md={4} lg={5} xl={4}>
      <Typography variant="h5" fontWeight="bold" gutterBottom>
        Users
      </Typography>
      <Card>
        {userList.length > 0 ? (
          <CardContent style={{ maxHeight: 600, overflow: "auto" }}>
            {userList.map((user, index) => (
              <div
                key={index}
                data-sender={user.id}
                onClick={() => {
                  // console.log(user, "user");
                  setSelectedUser(user);
                }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px 0",
                  borderBottom: "1px solid #ddd",
                  backgroundColor: selectedUser?.id == user?.id ? "#FF8300" : "transparent"
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    alt={`${user.firstName} ${user.lastName || ""}`.trim()}
                    src={
                      `${apiBaseURL}/img/${user?.profilePic}` || "https://via.placeholder.com/40"
                    }
                    style={{ marginRight: "15px" }}
                  />
                  <div>
                    <Typography style={{ fontSize: "16px" }} variant="subtitle1" fontWeight="bold">
                      {user.firstName} {user.lastName || ""}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {truncateMessage(user.message)}
                    </Typography>
                  </div>
                </div>
                <div style={{ textAlign: "center" }}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{ marginBottom: "5px" }}
                  >
                    {getTimeDifference(user.sendAt)}
                  </Typography>
                  {user.seenAt ? (
                    <Typography variant="caption" color="text.secondary">
                      ✔
                    </Typography>
                  ) : (
                    <Badge color="error" badgeContent={user.numberOfUnseenMessages} />
                  )}
                </div>
              </div>
            ))}
          </CardContent>
        ) : (
          <CardContent
            style={{
              height: 610,
              overflow: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <div>No messages yet!</div>
          </CardContent>
        )}
      </Card>
    </Grid>
  );
};

export default ChatList;
