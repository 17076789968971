import pxToRem from "assets/theme-dark/functions/pxToRem";

const dialogActions = {
  styleOverrides: {
    root: {
      padding: pxToRem(16)
    }
  }
};

export default dialogActions;
