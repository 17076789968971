import AddIcon from "@mui/icons-material/Add";
import { default as CancelIcon, default as CloseIcon } from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { Avatar, Badge, IconButton, InputLabel, TextField } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarContainer
} from "@mui/x-data-grid";
import axios from "axios";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import customerDefaultImage from "../../assets/images/customlogo/image.png";
import uploadFileImage from "../../assets/images/logos/image.png";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1
});

const userType = 1;
const appColor = "#F44F2A";
const initialRows = [];
const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4
};

function EditToolbar(props) {
  const { setRows, setRowModesModel, addedUser, setAddedUser } = props;
  const [open, setOpen] = React.useState(false);
  const [profilePic, setProfilePic] = useState("");
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [shrink1, setShrink1] = useState(false);
  const [shrink2, setShrink2] = useState(false);
  const [shrink3, setShrink3] = useState(false);
  const [clickedClosed, setClickClosed] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setClickClosed(!clickedClosed);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfilePic(file);
    }
  };

  useEffect(() => {
    setFirstName("");
    setEmail("");
    setPhoneNumber("");
    setProfilePic("");
    setFirstNameError("");
    setEmailError("");
    setPhoneNumberError("");
  }, [clickedClosed, addedUser]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (firstName == "") {
      setFirstNameError("Name is required!");
    } else if (!/^([a-zA-Z ]){2,30}$/.test(firstName)) {
      setFirstNameError("Please enter valid name!");
    } else if (email == "") {
      setEmailError("Email is required!");
    } else if (phoneNumber == "") {
      setPhoneNumberError("Mobile number is required!");
    } else if (!/^[0-9]{10}$/.test(phoneNumber)) {
      setPhoneNumberError("Please enter valid mobile number!");
    } else {
      console.log(profilePic, firstName, email, phoneNumber, userType, "add user  parameter");
      const formData = new FormData();
      formData.append("profilePic", profilePic);
      formData.append("countryCode", "+91");
      formData.append("firstName", firstName);
      formData.append("lastName", "");
      formData.append("email", email);
      formData.append("phoneNumber", phoneNumber);
      formData.append("userType", userType);
      const headers = {
        "Content-Type": "multipart/form-data",
        "x-access-token": `${localStorage.getItem("token")}`
      };
      axios
        .post(`${apiBaseURL}/api/v1/admin/adduser`, formData, {
          headers: headers
        })
        .then((res) => {
          if (res.data) {
            setOpen(false);
            setAddedUser(!addedUser);
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "New customer has been created!!",
              showConfirmButton: false,
              timer: 1000
            });
          }
        })
        .catch((error) => {
          if (error.response.data.message == "Phone number already exists") {
            setPhoneNumberError("Phone number already exists!!");
          } else if (error.response.data.message == "Email already exists") {
            setEmailError("Email already exists!!");
          } else {
            setPhoneNumberError("");
            setEmailError("");
          }
        });
    }
  };

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`
  }));

  return (
    <GridToolbarContainer sx={{ justifyContent: "flex-end" }}>
      <Button
        sx={{ color: "#F44F2A", border: "1px solid grey" }}
        startIcon={<AddIcon />}
        onClick={handleOpen}
      >
        Add Customer
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500
          }
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={(theme) => ({
                position: "absolute",
                right: 8,
                top: 8,
                color: theme.palette.grey[500]
              })}
            >
              <CloseIcon />
            </IconButton>
            <form autoComplete="off" onSubmit={handleSubmit}>
              <Box mb={2}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    <SmallAvatar
                      alt="Remy Sharp"
                      src={uploadFileImage}
                      onClick={() => document.getElementById("picture").click()}
                    />
                  }
                >
                  <Avatar
                    sx={{
                      width: "80px",
                      height: "80px",
                      boxShadow: "0px 5px 10px 0px #163F7533",
                      p: !profilePic && 3
                    }}
                    alt="Profile Picture"
                    src={profilePic ? profilePic : customerDefaultImage}
                  />
                  <VisuallyHiddenInput
                    id="picture"
                    name="picture"
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </Badge>
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Name"
                  name="firstName"
                  value={firstName}
                  // helperText={userName?'':"Please enter valid username"}
                  onChange={(event) => {
                    setFirstName(event.target.value);
                    if (event.target.value) {
                      setFirstNameError("");
                      setShrink1(true);
                    } else {
                      setFirstNameError("Username is required!");
                      setShrink1(false);
                    }
                  }}
                  InputLabelProps={{ shrink: shrink1 }}
                />
                <InputLabel sx={{ pt: 1, color: "red" }}>{firstNameError}</InputLabel>
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  value={email}
                  // helperText={password?'':"Please enter valid password"}
                  onChange={(event) => {
                    setEmail(event.target.value);
                    if (event.target.value) {
                      setEmailError("");
                      setShrink2(true);
                    } else {
                      setEmailError("Email is required!");
                      setShrink2(false);
                    }
                  }}
                  InputLabelProps={{ shrink: shrink2 }}
                  inputProps={{
                    type: "email"
                  }}
                />
                <InputLabel sx={{ pt: 1, color: "red" }}>{emailError}</InputLabel>
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Mobile Number"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={(event) => {
                    setPhoneNumber(event.target.value);
                    if (event.target.value) {
                      setPhoneNumberError("");
                      setShrink3(true);
                    } else {
                      setPhoneNumberError("Mobile number is required!");
                      setShrink3(false);
                    }
                  }}
                  InputLabelProps={{ shrink: shrink3 }}
                  inputProps={{
                    pattern: "^(+d{1,2}s)?(?d{3})?[s.-]d{3}[s.-]d{4}$"
                  }}
                />
                <InputLabel sx={{ pt: 1, color: "red" }}>{phoneNumberError}</InputLabel>
              </Box>
              <Box mt={4} mb={1}>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    backgroundColor: appColor,
                    color: "#FFFFFF",
                    ":hover": {
                      backgroundColor: appColor
                    }
                  }}
                  fullWidth
                >
                  Register Customer
                </Button>
              </Box>
            </form>
          </Box>
        </Fade>
      </Modal>
    </GridToolbarContainer>
  );
}

const Customers = () => {
  const [rows, setRows] = React.useState(initialRows);
  const [addedUser, setAddedUser] = useState(false);
  const [calledBlockUser, setCallBlockUser] = useState(false);
  const [calledUpdateUser, setCallUpdateUser] = useState(false);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [profilePic, setProfilePic] = React.useState({});
  const [searchBy, setSearchBy] = useState("");

  useEffect(() => {
    (async () => {
      const userType = 1;
      const headers = {
        "Content-Type": "application/json",
        "x-access-token": `${localStorage.getItem("token")}`
      };
      const customerList = await axios.post(
        `${apiBaseURL}/api/v1/admin/users`,
        { searchBy, userType },
        {
          headers: headers
        }
      );
      if (customerList.data) {
        const { list } = customerList.data.data;
        const currentRows = list.map((value, index) => {
          return {
            id: value?.id,
            profilePic: value?.profilePic
              ? `<div><img src="${apiBaseURL}/img/${value?.profilePic}" height="50" width="50"></div> `
              : `<div><img src="${apiBaseURL}/img/Profile.svg" height="50"></div> `,
            firstName: value?.firstName,
            email: value?.email,
            phoneNumber: value?.phoneNumber,
            isBlocked:
              value?.isBlocked === 0
                ? `<button style="background-color:green; border: 1px solid transparent; height:40px; width:80px; font-size:20px; border-radius: 0.375rem; font-family:"Roboto","Helvetica","Arial",sans-serif; color:white;">Block</button>`
                : `<button style="background-color:#D22B2B;border: 1px solid transparent; height:40px; width:80px; font-size:18px; border-radius: 0.375rem;
                font-family:"Roboto","Helvetica","Arial",sans-serif; color:white;">Unblock</button>`
          };
        });
        setRows(currentRows);
      }
    })();
  }, [calledBlockUser, addedUser, calledUpdateUser, searchBy]);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => {
    Swal.fire({
      title: `Do you want to delete this customer?`,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: appColor
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const headers = {
              "Content-Type": "application/json",
              "x-access-token": `${localStorage.getItem("token")}`
            };
            const deleteUser = await axios.delete(`${apiBaseURL}/api/v1/admin/deleteuser`, {
              headers: headers,
              data: {
                id
              }
            });
            if (deleteUser.data) {
              Swal.fire("The customer is deleted now!", "", "success");
              setRows(rows.filter((row) => row.id !== id));
            }
          } catch (error) {
            if (
              error.response.data.message ==
              "Customer is already having pending order so please let him complete the order and then try to delete it."
            ) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Customer is already having pending order so please let him complete the order and then try to delete him."
              });
            }
            console.log(error, "error");
          }
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const handleCancelClick = (id) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });
    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow) => {
    const { email, firstName, phoneNumber, id } = newRow;
    if (firstName == "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Name can't be blank!"
      });
    } else if (!/^([a-zA-Z ]){2,30}$/.test(firstName)) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter valid name!"
      });
    } else if (email == "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Email can't be blank!"
      });
    } else if (!/\S+@\S+\.\S+/.test(email.trim().toLowerCase())) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Email is not valid!"
      });
    } else if (phoneNumber == "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Mobile number can't be blank!"
      });
    } else if (!/^[0-9]{10}$/.test(phoneNumber)) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter valid mobile number!"
      });
    } else {
      try {
        const formData = new FormData();
        formData.append("profilePic", profilePic);
        formData.append("firstName", firstName);
        formData.append("email", email.trim().toLowerCase());
        formData.append("phoneNumber", phoneNumber);
        formData.append("userType", userType);
        formData.append("id", id);
        const updateUser = await axios.post(`${apiBaseURL}/api/v1/admin/updateuser`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-access-token": `${localStorage.getItem("token")}`
          }
        });
        if (updateUser) {
          const updatedRow = { ...newRow, isNew: false };
          setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
          setCallUpdateUser(!calledUpdateUser);
          return updatedRow;
        }
      } catch (error) {
        console.log(error, "error");
        if (error.response.data.message == "Id not provided!!") {
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: "Id not provided!!"
          });
        } else if (error.response.data.message == "No customer found for this id!!!") {
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: "No customer found for this id!!!"
          });
        } else if (error.response.data.message == "This mobile number is already registered!!") {
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: "This mobile number is already registered!!"
          });
        } else if (error.response.data.message == "This email is already registered!!") {
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: "This email is already registered!!"
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: `${error.response.data.message}`
          });
        }
      }
    }
  };

  const handleBlockButton = (phoneNumber, isBlocked) => {
    if (phoneNumber) {
      Swal.fire({
        title: `Do you want to ${isBlocked === true ? "unblock" : "block"} this user?`,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        confirmButtonColor: appColor
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              const blockUser = await axios.post(
                `${apiBaseURL}/api/v1/admin/blocking`,
                {
                  userType,
                  phoneNumber
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    "x-access-token": `${localStorage.getItem("token")}`
                  }
                }
              );
              if (blockUser.data) {
                Swal.fire(
                  `The user is ${isBlocked === true ? "unblocked" : "blocked"} now!`,
                  "",
                  "success"
                );
                setCallBlockUser(!calledBlockUser);
              }
            } catch (error) {
              console.log(error, "error in trycatch");
              Swal.fire({
                icon: "info",
                title: "Try later...",
                text: `${error.response.data.message}`
              });
            }
          } else if (result.isDenied) {
            Swal.fire("Changes are not saved", "", "info");
          }
        })
        .catch((error) => {
          console.log(error, "error in thencatch");
        });
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleFileChange = (e, params) => {
    const file = e.target.files[0];
    if (file) {
      const updatedRow = {
        ...params.row,
        // profilePic: `<img src="${URL.createObjectURL(file)}" />`,?
        profilePic: file
      };
      setProfilePic(updatedRow.profilePic);
      setRows((prevRows) => prevRows.map((row) => (row.id === params.id ? updatedRow : row)));
    }
  };

  const columns = [
    {
      field: "profilePic",
      headerName: "Profile Pic",
      width: 180,
      editable: true,
      type: "file",
      renderCell: (params) => {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: params.value // Render the HTML content (image tag)
            }}
            style={{ width: "fit-content" }}
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <Box>
            <VisuallyHiddenInput
              id="picture"
              name="picture"
              type="file"
              accept="image/*"
              onChange={(e) => handleFileChange(e, params)}
            />
            <IconButton
              aria-label="upload"
              sx={{
                border: "1px solid #F2F2F2",
                borderRadius: 2
              }}
              onClick={() => document.getElementById("picture").click()}
            >
              <img height="20" width="20" src={uploadFileImage} alt="upload icon" />
            </IconButton>
          </Box>
        );
      }
    },
    ,
    { field: "firstName", headerName: "Name", width: 250, editable: true },
    {
      field: "phoneNumber",
      headerName: "Mobile Number",
      type: "string",
      width: 250,
      editable: true
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      editable: true,
      type: "email"
      // valueOptions: ["Market", "Finance", "Development"],
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 250,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main"
              }}
              onClick={() => {
                handleSaveClick(id);
              }}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={() => {
                handleCancelClick(id);
              }}
              color="inherit"
            />
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => {
              handleEditClick(id);
            }}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => {
              handleDeleteClick(id);
            }}
            color="inherit"
          />
        ];
      }
    },
    {
      field: "isBlocked",
      headerName: "Block/Unblock",
      width: 250,
      editable: false,
      type: "button",
      cellClassName: "actions",
      renderCell: (params) => {
        const { phoneNumber, isBlocked } = params.row;
        const blocked = isBlocked.includes("Unblock");
        return (
          <div
            onClick={() => {
              handleBlockButton(phoneNumber, blocked);
            }}
            dangerouslySetInnerHTML={{
              __html: params.value // Render the HTML content (image tag)
            }}
            style={{ width: "fit-content" }}
          />
        );
      }
    }
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box sx={{ width: "100%", marginBottom: 2 }}>
        <TextField
          id="outlined-basic"
          label="Search by name, email or mobile number"
          variant="outlined"
          value={searchBy}
          onChange={(event) => {
            setSearchBy(event.target.value);
          }}
          sx={{
            width: "300px"
          }}
        />
      </Box>
      <Box
        sx={{
          height: 675,
          width: "100%",
          "& .actions": {
            color: "text.secondary"
          },
          "& .textPrimary": {
            color: "text.primary"
          }
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{
            toolbar: EditToolbar
          }}
          sx={{
            fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
            fontWeight: 400
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } }
          }}
          pageSizeOptions={[10, 20, 30]}
          slotProps={{
            toolbar: { setRows, setRowModesModel, addedUser, setAddedUser }
          }}
        />
      </Box>
    </DashboardLayout>
  );
};

export default Customers;
