import { Container, Grid } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import ChatHistory from "./components/ChatHistory";
import ChatList from "./components/ChatList";
import { socket } from "../../socket";

export default function Chats() {
  const [selectedUser, setSelectedUser] = useState(null);
  const [newMessage, setNewMessage] = useState({});
  const [seenMessageCalled, setSeenMessageCalled] = useState({});
  const [newMessageCame, setNewMessageCame] = useState({});
  socket.on("newMessageCame", (data) => {
    if (typeof data == "object") {
      // console.log("new message came and listened..");
      setNewMessageCame(data);
    }
  });
  useEffect(() => {
    // console.log("re-render at newMessageCame");
  }, [newMessageCame]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container maxWidth="xl" style={{ padding: "20px", backgroundColor: "#eee" }}>
        <Grid container spacing={3}>
          <ChatList
            setSelectedUser={setSelectedUser}
            newMessage={newMessage}
            seenMessageCalled={seenMessageCalled}
            newMessageCame={newMessageCame}
            selectedUser={selectedUser}
          />

          <ChatHistory
            selectedUser={selectedUser}
            setNewMessage={setNewMessage}
            setSeenMessageCalled={setSeenMessageCalled}
            newMessage={newMessage}
          />
        </Grid>
      </Container>
    </DashboardLayout>
  );
}
