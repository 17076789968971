import Grid from "@mui/material/Grid";
import { orange } from "@mui/material/colors";
import runnerLogo from "../../assets/images/image.png";
import pendingOrderLogo from "../../assets/images/shipping-time (1).png";
import MDBox from "components/MDBox";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
import axios from "axios";
import { useEffect, useState } from "react";
import { socket } from "../../socket";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const appColor = orange[800];
  const [unseenMessages, setUnseenMessages] = useState(0);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [totalRunners, setTotalRunners] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [completedOrders, setCompletedOrders] = useState(0);
  const [pendingOrders, setPendingOrders] = useState(0);
  const [confirmedOrders, setConfirmedOrders] = useState(0);
  const [canceledOrders, setCanceledOrders] = useState(0);
  const [emitCalled, setEmitCalled] = useState(false);
  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      "x-access-token": `${localStorage.getItem("token")}`
    };
    axios
      .get(`${apiBaseURL}/api/v1/admin/dashboard`, {
        headers: headers
      })
      .then((res) => {
        if (res.data.data) {
          setTotalCustomers(res.data.data.totalCustomers);
          setTotalRunners(res.data.data.totalRunners);
          setTotalOrders(res.data.data.totalOrders);
          setCompletedOrders(res.data.data.completedOrders);
          setPendingOrders(res.data.data.pendingOrders);
          setConfirmedOrders(res.data.data.confirmedOrders);
          setCanceledOrders(res.data.data.canceledOrders);
          setUnseenMessages(res.data.data.numberOfUnseenMessages);
        } else {
          console.log("could not find the data!!");
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [emitCalled]);

  socket.on("unseenMessageForAdminPanel", (data) => {
    if (typeof data == "object") {
      const { numberOfUnseenMessages } = data;
      setUnseenMessages(numberOfUnseenMessages);
      setEmitCalled(!emitCalled);
    }
  });
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="light"
                icon="chat"
                title="Chats"
                count={unseenMessages}
                route="/chats"
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="people_alt"
                title="Customers"
                count={totalCustomers}
                route="/customers"
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon={<MDBox component="img" src={runnerLogo} alt="Brand" width="1.5rem" />}
                title="Runners"
                count={totalRunners}
                route="/runners"
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="add_shopping_cart"
                title="Total Orders"
                count={totalOrders}
                route="/orders"
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="secondary"
                icon={<MDBox component="img" src={pendingOrderLogo} alt="Brand" width="1.5rem" />}
                title="Pending Orders"
                count={pendingOrders}
                route="/orders"
                state={{ orderStatus: "pending" }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="warning"
                icon="auto_mode"
                title="In Progress Orders"
                count={confirmedOrders}
                route="/orders"
                state={{ orderStatus: "confirmed" }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="check_circle"
                title="Completed Orders"
                count={completedOrders}
                route="/orders"
                state={{ orderStatus: "completed" }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="error"
                icon="cancel"
                title="Cancelled Orders"
                count={canceledOrders}
                route="/orders"
                state={{ orderStatus: "canceled" }}
              />
            </MDBox>
          </Grid>
        </Grid>
        {/* <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="website views"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="daily sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Projects />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </MDBox> */}
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
