import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import axios from "axios";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useState } from "react";
import Swal from "sweetalert2";

function Notifications() {
  const [successSB, setSuccessSB] = useState(false);
  const [infoSB, setInfoSB] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [userType, setUserType] = useState("");
  const [shrink1, setShrink1] = useState(false);
  const [shrink2, setShrink2] = useState(false);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [userTypeError, setUserTypeError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [textError, setTextError] = useState("");
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);
  const openWarningSB = () => setWarningSB(true);
  const closeWarningSB = () => setWarningSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  const userTypes = [
    {
      value: 1,
      label: "Customers"
    },
    {
      value: 2,
      label: "Runners"
    },
    {
      value: 3,
      label: "All Users"
    }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userType == "") {
      setUserTypeError("Please select the usertypes!");
    } else if (title == "") {
      setTitleError("Title is required!");
    } else if (text == "") {
      setTextError("Body is required!");
    } else {
      axios
        .post(
          `${apiBaseURL}/api/v1/admin/sendnotification`,
          { userType, title, text },
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": `${localStorage.getItem("token")}`
            }
          }
        )
        .then((res) => {
          if (res.data) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Notification sent successfully!!",
              showConfirmButton: false,
              timer: 1000
            });
            setTitle("");
            setText("");
            setUserType("");
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card sx={{ maxWidth: 700 }}>
        <CardContent>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Box mt={2}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select Users</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  fullWidth
                  value={userType}
                  onChange={(event) => {
                    setUserType(event.target.value);
                    setUserTypeError("");
                  }}
                  defaultValue={userType}
                  label="Select Users"
                  sx={{ height: "45px" }}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      sx={{ height: "100%", alignItems: "center", fontSize: "x-large" }}
                    >
                      <KeyboardArrowDownIcon />
                    </InputAdornment>
                  }
                >
                  {userTypes.map((option) => (
                    <MenuItem sx={{ height: "45px" }} key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <InputLabel sx={{ pt: 1, color: "red" }}>{userTypeError}</InputLabel>
            </Box>
            <Box mt={4}>
              <TextField
                fullWidth
                label="Title"
                name="title"
                value={title}
                onChange={(event) => {
                  setTitle(event.target.value);
                  if (event.target.value) {
                    setTitleError("");
                    setShrink1(true);
                  } else {
                    setTitleError("Title is required!");
                    setShrink1(false);
                  }
                }}
                InputLabelProps={{ shrink: shrink1 }}
                inputProps={{
                  type: "text"
                }}
              />
              <InputLabel sx={{ pt: 1, color: "red" }}>{titleError}</InputLabel>
            </Box>
            <Box mt={4}>
              <TextField
                fullWidth
                label="Body"
                name="text"
                value={text}
                onChange={(event) => {
                  setText(event.target.value);
                  if (event.target.value) {
                    setTextError("");
                    setShrink2(true);
                  } else {
                    setTextError("Body is required!");
                    setShrink2(false);
                  }
                }}
                InputLabelProps={{ shrink: shrink2 }}
                inputProps={{
                  type: "text"
                }}
              />
              <InputLabel sx={{ pt: 1, color: "red" }}>{textError}</InputLabel>
            </Box>
            <Box mt={3}>
              <Button
                variant="contained"
                sx={{
                  color: "#FFFFFF"
                }}
                type="submit"
              >
                Send Notification
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}

export default Notifications;
