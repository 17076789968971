import axios from "axios";
import { useState } from "react";
import * as React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControlLabel from "@mui/material/FormControlLabel";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { Box, Button, IconButton, InputLabel, TextField } from "@mui/material";
import { convertLength } from "@mui/material/styles/cssUtils";

function Basic() {
  const [showPassword, setShowPassword] = React.useState(false);
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [shrink1, setShrink1] = useState(false);
  const [shrink2, setShrink2] = useState(false);
  const appColor = "#F44F2A";
  const navigate = useNavigate();
  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userName == "") {
      setUserNameError("Username is required!");
    } else if (password == "") {
      setPasswordError("Password is required!");
    } else {
      axios
        .post(`${apiBaseURL}/api/v1/admin/sign-in`, {
          userName,
          password
        })
        .then((res) => {
          const token = res.data.data.token;
          if (token) {
            localStorage.setItem("token", token);
            navigate("/dashboard");
          }
        })
        .catch((error) => {
          console.log(error, "error");
          if (error.response.data.message == "Invalid Username!!") {
            setUserNameError("Invalid Username!!");
          } else if (error.response.data.message == "Invalid password!!") {
            setPasswordError("Invalid password!!");
          } else {
            setUserNameError("");
            setPasswordError("");
          }
        });
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <BasicLayout>
        <Card>
          <MDBox textAlign="center" pb={3} py={4}>
            <Box
              component="img"
              sx={{
                height: 100,
                width: "auto",
                alignItems: "center"
              }}
              alt="The house from the offer."
              src={process.env.PUBLIC_URL + "/image.png"}
            />
          </MDBox>
          <MDBox
            bgColor={appColor}
            color={appColor}
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Sign in
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <form autoComplete="off" onSubmit={handleSubmit}>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Username"
                  name="userName"
                  value={userName}
                  // helperText={userName?'':"Please enter valid username"}
                  onChange={(event) => {
                    setUsername(event.target.value);
                    if (event.target.value) {
                      setUserNameError("");
                      setShrink1(true);
                    } else {
                      setUserNameError("Username is required!");
                      setShrink1(false);
                    }
                  }}
                  InputLabelProps={{ shrink: shrink1 }}
                />
                <InputLabel sx={{ pt: 1, color: "red" }}>{userNameError}</InputLabel>
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                    if (event.target.value) {
                      setPasswordError("");
                      setShrink2(true);
                    } else {
                      setPasswordError("Password is required!");
                      setShrink2(false);
                    }
                  }}
                  InputLabelProps={{ shrink: shrink2 }}
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <InputLabel sx={{ pt: 1, color: "red" }}>{passwordError}</InputLabel>
              </Box>
              <Box mt={4} mb={1}>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    backgroundColor: appColor,
                    color: "#FFFFFF",
                    ":hover": {
                      backgroundColor: appColor
                    }
                  }}
                  fullWidth
                >
                  sign in
                </Button>
              </Box>
            </form>
          </MDBox>
        </Card>
      </BasicLayout>
    </div>
  );
}

export default Basic;
