import SignIn from "layouts/authentication/sign-in";
import Customers from "layouts/customers";
import Dashboard from "layouts/dashboard";
import Notifications from "layouts/notifications";
import Orders from "layouts/orders";
import Runners from "layouts/runners";
import Payments from "layouts/payment";
import RunnerLogo from "./assets/images/image.png";
import { Box } from "@mui/material";
import Icon from "@mui/material/Icon";
import Chats from "layouts/chats";
import { useState, useEffect } from "react";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />
  },
  {
    type: "collapse",
    name: "Customers",
    key: "customers",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/customers",
    component: <Customers />
  },
  {
    type: "collapse",
    name: "Runners",
    key: "runners",
    icon: (
      <Box fontSize="small">
        <Box
          component="img"
          sx={{
            height: 20,
            border: "black",
            width: "auto",
            alignItems: "center"
          }}
          alt="The house from the offer."
          src={RunnerLogo}
        />
      </Box>
    ),
    route: "/runners",
    component: <Runners />
  },
  {
    type: "collapse",
    name: "Orders",
    key: "orders",
    icon: <Icon fontSize="small">add_shopping_cart</Icon>,
    route: "/orders",
    component: <Orders />
  },
  {
    type: "collapse",
    name: "Payments",
    key: "payments",
    icon: <Icon fontSize="small">payment_icon</Icon>,
    route: "/payments",
    component: <Payments />
  },
  {
    type: "collapse",
    name: `Chats`,
    key: "chats",
    icon: <Icon fontSize="small">chat</Icon>,
    route: "/chats",
    component: <Chats />
  },
  {
    type: "collapse",
    name: "Push Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/sign-in",
    component: <SignIn />
  }
];

export default routes;
