import AddIcon from "@mui/icons-material/Add";
import { default as CancelIcon, default as CloseIcon } from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import {
  Avatar,
  Badge,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarContainer
} from "@mui/x-data-grid";
import axios from "axios";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";
import Swal from "sweetalert2";
import customerDefaultImage from "../../assets/images/customlogo/image.png";
import uploadFileImage from "../../assets/images/logos/image.png";
import "./Index.css";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1
});

const userType = 2;
const appColor = "#F44F2A";
const initialRows = [];
const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4
};

const BankDetailsModal = ({ open, onClose, details }) => {
  if (!details) return null;

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2
        }}
      >
        <Typography variant="h6">Bank Details</Typography>
        <Typography>Last 4 Digits: {details.last4 || "N/A"}</Typography>
        <Typography>Routing Number: {details.routing_number || "N/A"}</Typography>
        <Typography>Bank Name: {details.bank_name || "N/A"}</Typography>
      </Box>
    </Modal>
  );
};

function EditToolbar(props) {
  const { setRows, setRowModesModel, addedUser, setAddedUser } = props;
  const [open, setOpen] = React.useState(false);
  const [profilePic, setProfilePic] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [vehicleTypeError, setVehicleTypeError] = useState("");
  const [shrink1, setShrink1] = useState(false);
  const [shrink2, setShrink2] = useState(false);
  const [shrink3, setShrink3] = useState(false);
  const [clickedClosed, setClickClosed] = useState(false);

  const vehicleTypes = [
    {
      value: 1,
      label: "Bicycle"
    },
    {
      value: 2,
      label: "By Foot"
    },
    {
      value: 3,
      label: "By Public Transport"
    }
  ];

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setClickClosed(!clickedClosed);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfilePic(file);
    }
  };

  useEffect(() => {
    setFullName("");
    setEmail("");
    setPhoneNumber("");
    setProfilePic("");
    setVehicleType("");
    setFullNameError("");
    setEmailError("");
    setPhoneNumberError("");
    setVehicleTypeError("");
  }, [clickedClosed, addedUser]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (fullName == "") {
      setFullNameError("Name is required!");
    } else if (!/^[a-zA-Z.+'-]+(?:\s[a-zA-Z.+'-]+)*\s?$/.test(fullName)) {
      setFullNameError(
        "Please enter valid name! Make sure there is only one blank space between name and surname."
      );
    } else if (email == "") {
      setEmailError("Email is required!");
    } else if (phoneNumber == "") {
      setPhoneNumberError("Mobile number is required!");
    } else if (!/^[0-9]{10}$/.test(phoneNumber)) {
      setPhoneNumberError("Please enter valid mobile number!");
    } else if (vehicleType == "") {
      setVehicleTypeError("Please select vehilce type");
    } else {
      console.log(
        profilePic,
        fullName,
        email,
        phoneNumber,
        userType,
        vehicleType,
        "add user  parameter"
      );
      let [firstName, lastName] = fullName.trim().split(" ");
      const formData = new FormData();
      formData.append("profilePic", profilePic);
      formData.append("countryCode", "+91");
      formData.append("firstName", firstName);
      formData.append("lastName", lastName ? lastName : "");
      formData.append("email", email.trim());
      formData.append("phoneNumber", phoneNumber.trim());
      formData.append("userType", userType);
      formData.append("vehicleType", vehicleType);
      const headers = {
        "Content-Type": "multipart/form-data",
        "x-access-token": `${localStorage.getItem("token")}`
      };
      axios
        .post(`${apiBaseURL}/api/v1/admin/adduser`, formData, {
          headers: headers
        })
        .then((res) => {
          if (res.data) {
            setOpen(false);
            setAddedUser(!addedUser);
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "New runner has been created!!",
              showConfirmButton: false,
              timer: 1000
            });
          }
        })
        .catch((error) => {
          if (error.response.data.message == "Phone number already exists") {
            setPhoneNumberError("Phone number already exists!!");
          } else if (error.response.data.message == "Email already exists") {
            setEmailError("Email already exists!!");
          } else {
            setPhoneNumberError("");
            setEmailError("");
          }
        });
    }
  };

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`
  }));

  return (
    <GridToolbarContainer sx={{ justifyContent: "flex-end" }}>
      <Button
        sx={{ color: "#F44F2A", border: "1px solid grey" }}
        startIcon={<AddIcon />}
        onClick={handleOpen}
      >
        Add Runner
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500
          }
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={(theme) => ({
                position: "absolute",
                right: 8,
                top: 8,
                color: theme.palette.grey[500]
              })}
            >
              <CloseIcon />
            </IconButton>
            <form autoComplete="off" onSubmit={handleSubmit}>
              <Box mb={2}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    <SmallAvatar
                      alt="Remy Sharp"
                      src={uploadFileImage}
                      onClick={() => document.getElementById("picture").click()}
                    />
                  }
                >
                  <Avatar
                    sx={{
                      width: "80px",
                      height: "80px",
                      boxShadow: "0px 5px 10px 0px #163F7533",
                      p: !profilePic && 3
                    }}
                    alt="Profile Picture"
                    src={profilePic ? profilePic : customerDefaultImage}
                  />
                  <VisuallyHiddenInput
                    id="picture"
                    name="picture"
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </Badge>
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Name"
                  name="fullName"
                  value={fullName}
                  // helperText={userName?'':"Please enter valid username"}
                  onChange={(event) => {
                    setFullName(event.target.value);
                    if (event.target.value) {
                      setFullNameError("");
                      setShrink1(true);
                    } else {
                      setFullNameError("Username is required!");
                      setShrink1(false);
                    }
                  }}
                  InputLabelProps={{ shrink: shrink1 }}
                />
                <InputLabel sx={{ pt: 1, color: "red" }}>{fullNameError}</InputLabel>
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  value={email}
                  // helperText={password?'':"Please enter valid password"}
                  onChange={(event) => {
                    setEmail(event.target.value);
                    if (event.target.value) {
                      setEmailError("");
                      setShrink2(true);
                    } else {
                      setEmailError("Email is required!");
                      setShrink2(false);
                    }
                  }}
                  InputLabelProps={{ shrink: shrink2 }}
                  inputProps={{
                    type: "email"
                  }}
                />
                <InputLabel sx={{ pt: 1, color: "red" }}>{emailError}</InputLabel>
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Mobile Number"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={(event) => {
                    setPhoneNumber(event.target.value);
                    if (event.target.value) {
                      setPhoneNumberError("");
                      setShrink3(true);
                    } else {
                      setPhoneNumberError("Mobile number is required!");
                      setShrink3(false);
                    }
                  }}
                  InputLabelProps={{ shrink: shrink3 }}
                  inputProps={{
                    pattern: "^(+d{1,2}s)?(?d{3})?[s.-]d{3}[s.-]d{4}$"
                  }}
                />
                <InputLabel sx={{ pt: 1, color: "red" }}>{phoneNumberError}</InputLabel>
              </Box>
              <Box mb={2}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Vehicle Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    fullWidth
                    value={vehicleType}
                    onChange={(event) => {
                      setVehicleType(event.target.value);
                    }}
                    defaultValue={vehicleType}
                    label="Vehicle Type"
                    sx={{ height: "45px" }}
                  >
                    {vehicleTypes.map((option) => (
                      <MenuItem sx={{ height: "45px" }} key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <InputLabel sx={{ pt: 1, color: "red" }}>{vehicleTypeError}</InputLabel>
              </Box>
              <Box mt={4} mb={1}>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    backgroundColor: appColor,
                    color: "#FFFFFF",
                    ":hover": {
                      backgroundColor: appColor
                    }
                  }}
                  fullWidth
                >
                  Register Runner
                </Button>
              </Box>
            </form>
          </Box>
        </Fade>
      </Modal>
    </GridToolbarContainer>
  );
}

const Runners = () => {
  const [rows, setRows] = React.useState(initialRows);
  const [addedUser, setAddedUser] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedBankDetails, setSelectedBankDetails] = useState(null);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [calledBlockUser, setCallBlockUser] = useState(false);
  const [calledApprovedUser, setCallApprovedUser] = useState(false);
  const [profilePic, setProfilePic] = React.useState({});
  const [calledUpdateUser, setCallUpdateUser] = useState(false);
  const [searchBy, setSearchBy] = useState("");

  const checkVehicleType = (type) => {
    if (type == "1") {
      return "Bicycle";
    } else if (type == "2") {
      return "By Foot";
    } else {
      return "By Public Transport";
    }
  };

  const handleOpenModal = (details) => {
    setSelectedBankDetails(details);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedBankDetails(null);
    setModalOpen(false);
  };

  useEffect(() => {
    (async () => {
      const headers = {
        "Content-Type": "application/json",
        "x-access-token": `${localStorage.getItem("token")}`
      };
      const runnerList = await axios.post(
        `${apiBaseURL}/api/v1/admin/users`,
        { searchBy, userType },
        {
          headers: headers
        }
      );
      if (runnerList.data) {
        const { list } = runnerList.data.data;
        const currentRows = list.map((value, index) => {
          return {
            id: value?.id,
            profilePic: value?.profilePic
              ? `<img src="${apiBaseURL}/img/${value?.profilePic}" height="50" width="50"> `
              : `<img src="${apiBaseURL}/img/Profile.svg" height="50"> `,
            fullName: `${value?.firstName} ${value?.lastName}`,
            email: value?.email,
            phoneNumber: value?.phoneNumber,
            vehicleType: checkVehicleType(value?.runnerVehicleType),
            runnerDocStatus: value?.runnerDocStatus,
            stripeStatus: value?.stripeStatus,
            adminApprovel:
              value?.adminApprovel == "1"
                ? `<button style="background-color:green; border: 1px solid transparent; height:40px; width:100px; font-size:20px; border-radius: 0.375rem; font-family:"Roboto","Helvetica","Arial",sans-serif; color:white;">Approved</button>`
                : `<button style="background-color:#D22B2B;border: 1px solid transparent; height:40px; width:100px; font-size:18px; border-radius: 0.375rem;
                font-family:"Roboto","Helvetica","Arial",sans-serif; color:white;">Pending</button>`,
            isBlocked:
              value?.isBlocked === 0
                ? `<button style="background-color:green; border: 1px solid transparent; height:40px; width:80px; font-size:20px; border-radius: 0.375rem; font-family:"Roboto","Helvetica","Arial",sans-serif; color:white;">Block</button>`
                : `<button style="background-color:#D22B2B;border: 1px solid transparent; height:40px; width:80px; font-size:18px; border-radius: 0.375rem;
                font-family:"Roboto","Helvetica","Arial",sans-serif; color:white;">Unblock</button>`,
            bankDetails: value.bankDetails
          };
        });
        setRows(currentRows);
      }
    })();
  }, [addedUser, calledBlockUser, calledUpdateUser, searchBy, calledApprovedUser]);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => {
    Swal.fire({
      title: `Do you want to delete this runner?`,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: appColor
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const headers = {
              "Content-Type": "application/json",
              "x-access-token": `${localStorage.getItem("token")}`
            };
            const deleteUser = await axios.delete(`${apiBaseURL}/api/v1/admin/deleteuser`, {
              headers: headers,
              data: {
                id
              }
            });
            if (deleteUser.data) {
              Swal.fire("The runner is deleted now!", "", "success");
              setRows(rows.filter((row) => row.id !== id));
            }
          } catch (error) {
            if (
              error.response.data.message ==
              "Runner is already having pending order so please let him complete the order and then try to delete it."
            ) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Runner is already having pending order so please let him complete the order and then try to delete him."
              });
            }
            console.log(error, "error");
          }
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const handleCancelClick = (id) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow) => {
    const { email, fullName, phoneNumber, id, vehicleType } = newRow;
    console.log(vehicleType, "vehicleType");
    if (fullName == "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Name can't be blank!"
      });
    } else if (!/^[a-zA-Z.+'-]+(?:\s[a-zA-Z.+'-]+)*\s?$/.test(fullName.trim())) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter valid name! Make sure there is only one blank space between name and surname."
      });
    } else if (email == "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Email can't be blank!"
      });
    } else if (!/\S+@\S+\.\S+/.test(email.trim().toLowerCase())) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Email is not valid!"
      });
    } else if (phoneNumber == "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Mobile number can't be blank!"
      });
    } else if (!/^[0-9]{10}$/.test(phoneNumber.trim())) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter valid mobile number!"
      });
    } else {
      try {
        let [firstName, lastName] = fullName.split(" ");
        const checkVehicleType = (vehicleType) => {
          if (vehicleType == "Bicycle") {
            return 1;
          } else if (vehicleType == "By Foot") {
            return 2;
          } else {
            return 3;
          }
        };
        const formData = new FormData();
        formData.append("profilePic", profilePic);
        formData.append("firstName", firstName);
        formData.append("lastName", lastName ? lastName : "");
        formData.append("email", email.trim().toLowerCase());
        formData.append("phoneNumber", phoneNumber.trim());
        formData.append("userType", userType);
        formData.append("runnerVehicleType", checkVehicleType(vehicleType));
        formData.append("id", id);
        const updateUser = await axios.post(`${apiBaseURL}/api/v1/admin/updateuser`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-access-token": `${localStorage.getItem("token")}`
          }
        });
        if (updateUser) {
          const updatedRow = { ...newRow, isNew: false };
          setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
          setCallUpdateUser(!calledUpdateUser);
          return updatedRow;
        }
      } catch (error) {
        console.log(error, "error");
        if (error.response.data.message == "Id not provided!!") {
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: "Id not provided!!"
          });
        } else if (error.response.data.message == "No runner found for this id!!!") {
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: "No runner found for this id!!!"
          });
        } else if (error.response.data.message == "This mobile number is already registered!!") {
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: "This mobile number is already registered!!"
          });
        } else if (error.response.data.message == "This email is already registered!!") {
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: "This email is already registered!!"
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: `${error.response.data.message}`
          });
        }
      }
    }
  };

  const handleBlockButton = (phoneNumber, isBlocked) => {
    if (phoneNumber) {
      Swal.fire({
        title: `Do you want to ${isBlocked === true ? "unblock" : "block"} this user?`,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        confirmButtonColor: appColor
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              const blockUser = await axios.post(
                `${apiBaseURL}/api/v1/admin/blocking`,
                {
                  userType,
                  phoneNumber
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    "x-access-token": `${localStorage.getItem("token")}`
                  }
                }
              );
              if (blockUser.data) {
                Swal.fire(
                  `The user is ${isBlocked === true ? "unblocked" : "blocked"} now!`,
                  "",
                  "success"
                );
                setCallBlockUser(!calledBlockUser);
              }
            } catch (error) {
              console.log(error, "error in trycatch");
              Swal.fire({
                icon: "info",
                title: "Try later...",
                text: `${error.response.data.message}`
              });
            }
          } else if (result.isDenied) {
            Swal.fire("Changes are not saved", "", "info");
          }
        })
        .catch((error) => {
          console.log(error, "error in thencatch");
        });
    }
  };

  const handleApprovedButton = async (userId, runnerDocStatus, stripeStatus, adminApprovel) => {
    if (runnerDocStatus != "3" || !stripeStatus) {
      const ErrorStatement = (runnerDocStatus, stripeStatus) => {
        console.log(runnerDocStatus, stripeStatus, "runnerDocStatus, stripeStatus");
        if (runnerDocStatus == "3" && stripeStatus == false) {
          return "Bank verification is still pending of this runner!";
        }
        if (runnerDocStatus != "3" && stripeStatus == true) {
          return "Document verification is still pending of this runner!";
        }
        if (runnerDocStatus != "3" && stripeStatus == false) {
          return "Document and bank verification is still pending for this runner!";
        }
        if (!runnerDocStatus && !stripeStatus) {
          return "Document and bank verification is still pending for this runner!";
        }
        if (runnerDocStatus && runnerDocStatus != "3" && !stripeStatus) {
          return "Document and bank verification is still pending for this runner!";
        }
        if (runnerDocStatus && runnerDocStatus == "3" && !stripeStatus) {
          return "Bank verification is still pending of this runner!";
        }
        if (stripeStatus && !runnerDocStatus) {
          return "Document verification is still pending of this runner!";
        }
      };
      Swal.fire({
        title: `<strong>${ErrorStatement(runnerDocStatus, stripeStatus)}</u></strong>`,
        icon: "info",
        showCloseButton: true
      });
      return;
    }
    if (userId && adminApprovel.includes("Pending")) {
      Swal.fire({
        title: `Do you want to approve this runner?`,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        confirmButtonColor: appColor
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const approveUser = await axios.post(
              `${apiBaseURL}/api/v1/admin/approveuser`,
              {
                userId
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  "x-access-token": `${localStorage.getItem("token")}`
                }
              }
            );
            if (approveUser.data) {
              Swal.fire(`Runner has been approved successfully!`, "", "success");
              setCallApprovedUser(!calledApprovedUser);
            }
          } catch (error) {
            console.log(error, "error in trycatch");
            Swal.fire({
              icon: "info",
              title: "Try later...",
              text: `${error.response.data.message}`
            });
          }
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleFileChange = (e, params) => {
    const file = e.target.files[0];
    if (file) {
      const updatedRow = {
        ...params.row,
        // profilePic: `<img src="${URL.createObjectURL(file)}" />`,?
        profilePic: file
      };
      setProfilePic(updatedRow.profilePic);
      setRows((prevRows) => prevRows.map((row) => (row.id === params.id ? updatedRow : row)));
    }
  };

  const columns = [
    {
      field: "profilePic",
      headerName: "Profile Pic",
      width: 130,
      editable: true,
      type: "file",
      renderCell: (params) => {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: params.value // Render the HTML content (image tag)
            }}
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <Box>
            <VisuallyHiddenInput
              id="picture"
              name="picture"
              type="file"
              accept="image/*"
              onChange={(e) => handleFileChange(e, params)}
            />
            <IconButton
              aria-label="upload"
              sx={{
                border: "1px solid #F2F2F2",
                borderRadius: 2
              }}
              onClick={() => document.getElementById("picture").click()}
            >
              <img height="20" width="20" src={uploadFileImage} alt="upload icon" />
            </IconButton>
          </Box>
        );
      }
    },
    { field: "fullName", headerName: "Name", width: 130, editable: true },
    {
      field: "phoneNumber",
      headerName: "Mobile Number",
      type: "string",
      width: 130,
      editable: true
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      editable: true,
      type: "email"
    },
    {
      field: "vehicleType",
      headerName: "Vehicle Type",
      width: 160,
      editable: true,
      type: "singleSelect",
      valueOptions: ["Bicycle", "By Foot", "By Public Transport"]
    },
    {
      field: "runnerDocStatus",
      headerName: "Veriff Status",
      width: 130,
      renderCell: (params) =>
        params.row.runnerDocStatus == "3" ? (
          <IconButton>
            <FaCheckCircle />
          </IconButton>
        ) : (
          <IconButton>
            <ImCancelCircle />
          </IconButton>
        )
    },
    {
      field: "stripeStatus",
      headerName: "Bank Account Status",
      width: 190,
      renderCell: (params) =>
        params.row.stripeStatus ? (
          <IconButton>
            <FaCheckCircle />
          </IconButton>
        ) : (
          <IconButton>
            <ImCancelCircle />
          </IconButton>
        )
    },
    {
      field: "bankDetails",
      headerName: "Bank Details",
      width: 120,
      renderCell: (params) =>
        params.row.bankDetails ? (
          <IconButton sx={{ left: -30 }} onClick={() => handleOpenModal(params.row.bankDetails)}>
            <Button href="#text-buttons" style={{ textTransform: "none" }}>
              View Details
            </Button>
          </IconButton>
        ) : (
          "N/A"
        )
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 120,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main"
              }}
              onClick={() => {
                handleSaveClick(id);
              }}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={() => {
                handleCancelClick(id);
              }}
              color="inherit"
            />
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => {
              handleEditClick(id);
            }}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => {
              handleDeleteClick(id);
            }}
            color="inherit"
          />
        ];
      }
    },
    {
      field: "adminApprovel",
      headerName: "Status",
      width: 150,
      editable: false,
      type: "button",
      cellClassName: "actions",
      renderCell: (params) => {
        const { id, runnerDocStatus, stripeStatus, adminApprovel } = params.row;
        const userId = +id;
        return (
          <div
            onClick={() => {
              handleApprovedButton(userId, runnerDocStatus, stripeStatus, adminApprovel);
            }}
            dangerouslySetInnerHTML={{
              __html: params.value // Render the HTML content (image tag)
            }}
            style={{ width: "fit-content" }}
          />
        );
      }
    },
    {
      field: "isBlocked",
      headerName: "Block/Unblock",
      width: 200,
      editable: false,
      type: "button",
      cellClassName: "actions",
      renderCell: (params) => {
        const { phoneNumber, isBlocked } = params.row;
        const blocked = isBlocked.includes("Unblock");
        return (
          <div
            onClick={() => {
              handleBlockButton(phoneNumber, blocked);
            }}
            dangerouslySetInnerHTML={{
              __html: params.value // Render the HTML content (image tag)
            }}
            style={{ width: "fit-content" }}
          />
        );
      }
    }
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box sx={{ width: "100%", marginBottom: 2 }}>
        <TextField
          id="outlined-basic"
          label="Search by name, email or mobile number"
          variant="outlined"
          value={searchBy}
          onChange={(event) => {
            setSearchBy(event.target.value);
          }}
          sx={{
            width: "300px"
          }}
        />
      </Box>
      <Box
        sx={{
          height: 675,
          width: "100%",
          "& .actions": {
            color: "text.secondary"
          },
          "& .textPrimary": {
            color: "text.primary"
          }
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{
            toolbar: EditToolbar
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } }
          }}
          pageSizeOptions={[10, 20, 30]}
          sx={{
            fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
            fontWeight: 400
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel, addedUser, setAddedUser }
          }}
        />
        <BankDetailsModal
          open={modalOpen}
          onClose={handleCloseModal}
          details={selectedBankDetails}
        />
      </Box>
    </DashboardLayout>
  );
};

export default Runners;
