import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import PropTypes from "prop-types";
import LogoutIcon from "@mui/icons-material/Logout";
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";
import MDBox from "components/MDBox";
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
import {
  navbar,
  navbarContainer,
  navbarIconButton,
  navbarMobileMenu,
  navbarRow
} from "examples/Navbars/DashboardNavbar/styles";
import { Logout } from "../../../layouts/logout";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box, Button, InputAdornment, InputLabel, TextField, Tooltip } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import {
  setMiniSidenav,
  setOpenConfigurator,
  setTransparentNavbar,
  useMaterialUIController
} from "context";
import { FaLock } from "react-icons/fa";

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [display, setDisplay] = React.useState("none");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [shrink1, setShrink1] = useState(false);
  const [shrink2, setShrink2] = useState(false);
  const [shrink3, setShrink3] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const navigate = useNavigate();
  const appColor = "#F44F2A";
  const regexForPass = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    handleTransparentNavbar();

    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setPasswordError("");
    setNewPasswordError("");
    setConfirmPasswordError("");
    setOpen(false);
  };
  const handleSubmit = async () => {
    if (password === "") {
      setPasswordError("Current password is required!");
    } else if (!regexForPass.test(newPassword)) {
      setNewPasswordError(
        "New password should have atleast 8 letters with at least a symbol, upper and lower case letter and a number!"
      );
    } else if (newPassword === "") {
      setNewPasswordError("New password is required!");
    } else if (confirmPassword === "") {
      setConfirmPasswordError("Confirm password is required!");
    } else if (newPassword !== confirmPassword) {
      setConfirmPasswordError("Confirm password doesn't match the new password!!");
    } else {
      try {
        const headers = {
          "Content-Type": "application/json",
          "x-access-token": `${localStorage.getItem("token")}`
        };
        console.log(password, newPassword, confirmPassword, "requestData");
        const changePassword = await axios.post(
          `${apiBaseURL}/api/v1/admin/changepassword`,
          { password, newPassword, confirmPassword },
          {
            headers: headers
          }
        );
        if (changePassword) {
          console.log(changePassword.data.message, "response");
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: `${changePassword.data.message}`,
            showConfirmButton: false,
            timer: 1000
          });
          handleClose();
        }
      } catch (error) {
        if (error.response.data.message === "No password exist for this user!") {
          setPasswordError("No such password exist for this user!");
        } else if (error.response.data.message === "Please enter correct current password!!") {
          setPasswordError("Please enter correct current password!!");
        } else if (error.response.data.message) {
          console.log(error.response, "error");
        }
      }
    }
  };
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    }
  });

  const manageLogout = () => {
    Swal.fire({
      title: "Do you want to logout?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: appColor
    }).then((result) => {
      if (result.isConfirmed) {
        const isLogout = Logout();
        if (isLogout) {
          Swal.fire("You successfully logged out!", "", "success");
          navigate("/sign-in");
        }
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            {/* <MDBox pr={1}>
              <MDInput label="Search here" />
            </MDBox> */}
            <MDBox color={light ? "white" : "inherit"}>
              <Tooltip title="Logout">
                <IconButton onClick={manageLogout} sx={navbarIconButton} size="small" disableRipple>
                  <Icon>
                    <LogoutIcon />
                  </Icon>
                </IconButton>
              </Tooltip>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <Tooltip title="Change Password">
                <IconButton
                  onClick={handleClickOpen}
                  size="small"
                  color="inherit"
                  sx={navbarIconButton}
                  variant="contained"
                >
                  <Icon>
                    <FaLock />
                  </Icon>
                </IconButton>
              </Tooltip>
            </MDBox>
            <Box sx={{ display: display }}>
              <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                  component: "form",
                  onSubmit: (event) => {
                    event.preventDefault();
                  }
                }}
              >
                <DialogTitle>Change Password</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Please enter valid current password to set the new password.
                  </DialogContentText>

                  <TextField
                    fullWidth
                    autoFocus
                    label="Current Password"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={password}
                    onChange={(event) => {
                      setPassword(event.target.value.trim());
                      if (event.target.value) {
                        setPasswordError("");
                        setShrink1(true);
                      } else {
                        setPasswordError("Current password is required!");
                        setShrink1(false);
                      }
                    }}
                    InputLabelProps={{ shrink: shrink1 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    sx={{ marginTop: 4 }}
                  />
                  <InputLabel sx={{ pt: 1, color: "red", whiteSpace: "wrap" }} multiLine={true}>
                    {passwordError}
                  </InputLabel>

                  <TextField
                    fullWidth
                    label="New Password"
                    type={showNewPassword ? "text" : "password"}
                    name="newpassword"
                    value={newPassword}
                    onChange={(event) => {
                      setNewPassword(event.target.value.trim());
                      if (event.target.value) {
                        setNewPasswordError("");
                        setShrink2(true);
                      } else {
                        setNewPasswordError("New password is required!");
                        setShrink2(false);
                      }
                    }}
                    InputLabelProps={{ shrink: shrink2 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowNewPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showNewPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    sx={{ marginTop: 4 }}
                  />
                  <InputLabel sx={{ pt: 1, color: "red", whiteSpace: "wrap" }}>
                    {newPasswordError}
                  </InputLabel>
                  <TextField
                    fullWidth
                    label="Confirm Password"
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmpassword"
                    value={confirmPassword}
                    onChange={(event) => {
                      setConfirmPassword(event.target.value.trim());
                      if (event.target.value) {
                        setConfirmPasswordError("");
                        setShrink3(true);
                      } else {
                        setConfirmPasswordError("Confirm password is required!");
                        setShrink3(false);
                      }
                    }}
                    InputLabelProps={{
                      shrink: shrink3,
                      sx: {
                        fontSize: "13.5px",
                        "&.MuiOutlinedInput-notchedOutline": { fontSize: "13.5px" }
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    sx={{ marginTop: 4 }}
                  />
                  <InputLabel sx={{ pt: 1, color: "red", whiteSpace: "wrap" }} multiLine={true}>
                    {confirmPasswordError}
                  </InputLabel>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={handleSubmit} type="submit">
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false
};

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool
};

export default DashboardNavbar;
