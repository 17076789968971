import AddIcon from "@mui/icons-material/Add";
import { default as CloseIcon } from "@mui/icons-material/Close";
import { Avatar, Badge, IconButton, InputLabel, TextField } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import {
  DataGrid,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarContainer
} from "@mui/x-data-grid";
import axios from "axios";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import customerDefaultImage from "../../assets/images/customlogo/image.png";
import uploadFileImage from "../../assets/images/logos/image.png";
import { useLocation } from "react-router-dom";
import { socket } from "../../socket.js";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1
});

const userType = 1;
const appColor = "#F44F2A";
const initialRows = [];
const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4
};

function EditToolbar(props) {
  const { setRows, setRowModesModel, addedUser, setAddedUser } = props;
  const [open, setOpen] = React.useState(false);
  const [profilePic, setProfilePic] = useState("");
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [shrink1, setShrink1] = useState(false);
  const [shrink2, setShrink2] = useState(false);
  const [shrink3, setShrink3] = useState(false);
  const [clickedClosed, setClickClosed] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setClickClosed(!clickedClosed);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfilePic(file);
    }
  };

  useEffect(() => {
    setFirstName("");
    setEmail("");
    setPhoneNumber("");
    setProfilePic("");
    setFirstNameError("");
    setEmailError("");
    setPhoneNumberError("");
  }, [clickedClosed, addedUser]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (firstName == "") {
      setFirstNameError("Name is required!");
    } else if (!/^([a-zA-Z ]){2,30}$/.test(firstName)) {
      setFirstNameError("Please enter valid name!");
    } else if (email == "") {
      setEmailError("Email is required!");
    } else if (phoneNumber == "") {
      setPhoneNumberError("Mobile number is required!");
    } else if (!/^[0-9]{10}$/.test(phoneNumber)) {
      setPhoneNumberError("Please enter valid mobile number!");
    } else {
      const formData = new FormData();
      formData.append("profilePic", profilePic);
      formData.append("countryCode", "+91");
      formData.append("firstName", firstName);
      formData.append("lastName", "");
      formData.append("email", email);
      formData.append("phoneNumber", phoneNumber);
      formData.append("userType", userType);
      axios
        .post(`${apiBaseURL}/api/v1/admin/adduser`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then((res) => {
          if (res.data) {
            setOpen(false);
            setAddedUser(!addedUser);
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "New customer has been created!!",
              showConfirmButton: false,
              timer: 1000
            });
          }
        })
        .catch((error) => {
          if (error.response.data.message == "Phone number already exists") {
            setPhoneNumberError("Phone number already exists!!");
          } else if (error.response.data.message == "Email already exists") {
            setEmailError("Email already exists!!");
          } else {
            setPhoneNumberError("");
            setEmailError("");
          }
        });
    }
  };

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`
  }));

  return (
    <GridToolbarContainer sx={{ justifyContent: "flex-end" }}>
      <Button
        sx={{ color: "#F44F2A", border: "1px solid grey" }}
        startIcon={<AddIcon />}
        onClick={handleOpen}
      >
        Add Customer
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500
          }
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={(theme) => ({
                position: "absolute",
                right: 8,
                top: 8,
                color: theme.palette.grey[500]
              })}
            >
              <CloseIcon />
            </IconButton>
            <form autoComplete="off" onSubmit={handleSubmit}>
              <Box mb={2}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    <SmallAvatar
                      alt="Remy Sharp"
                      src={uploadFileImage}
                      onClick={() => document.getElementById("picture").click()}
                    />
                  }
                >
                  <Avatar
                    sx={{
                      width: "80px",
                      height: "80px",
                      boxShadow: "0px 5px 10px 0px #163F7533",
                      p: !profilePic && 3
                    }}
                    alt="Profile Picture"
                    src={profilePic ? profilePic : customerDefaultImage}
                  />
                  <VisuallyHiddenInput
                    id="picture"
                    name="picture"
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </Badge>
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Name"
                  name="firstName"
                  value={firstName}
                  // helperText={userName?'':"Please enter valid username"}
                  onChange={(event) => {
                    setFirstName(event.target.value);
                    if (event.target.value) {
                      setFirstNameError("");
                      setShrink1(true);
                    } else {
                      setFirstNameError("Username is required!");
                      setShrink1(false);
                    }
                  }}
                  InputLabelProps={{ shrink: shrink1 }}
                />
                <InputLabel sx={{ pt: 1, color: "red" }}>{firstNameError}</InputLabel>
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  value={email}
                  // helperText={password?'':"Please enter valid password"}
                  onChange={(event) => {
                    setEmail(event.target.value);
                    if (event.target.value) {
                      setEmailError("");
                      setShrink2(true);
                    } else {
                      setEmailError("Email is required!");
                      setShrink2(false);
                    }
                  }}
                  InputLabelProps={{ shrink: shrink2 }}
                  inputProps={{
                    type: "email"
                  }}
                />
                <InputLabel sx={{ pt: 1, color: "red" }}>{emailError}</InputLabel>
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Mobile Number"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={(event) => {
                    setPhoneNumber(event.target.value);
                    if (event.target.value) {
                      setPhoneNumberError("");
                      setShrink3(true);
                    } else {
                      setPhoneNumberError("Mobile number is required!");
                      setShrink3(false);
                    }
                  }}
                  InputLabelProps={{ shrink: shrink3 }}
                  inputProps={{
                    pattern: "^(+d{1,2}s)?(?d{3})?[s.-]d{3}[s.-]d{4}$"
                  }}
                />
                <InputLabel sx={{ pt: 1, color: "red" }}>{phoneNumberError}</InputLabel>
              </Box>
              <Box mt={4} mb={1}>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    backgroundColor: appColor,
                    color: "#FFFFFF",
                    ":hover": {
                      backgroundColor: appColor
                    }
                  }}
                  fullWidth
                >
                  Register Customer
                </Button>
              </Box>
            </form>
          </Box>
        </Fade>
      </Modal>
    </GridToolbarContainer>
  );
}

const Payments = () => {
  const [rows, setRows] = React.useState(initialRows);
  const [addedUser, setAddedUser] = useState(false);
  const [calledCancelOrder, setCalledCancelOrder] = useState(false);
  const [calledUpdateUser, setCallUpdateUser] = useState(false);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [profilePic, setProfilePic] = React.useState({});
  const [searchBy, setSearchBy] = useState("");
  const location = useLocation();
  const state = location.state;

  function confirmedClicked() {
    console.log("click on the confimred...");
  }

  const handleStatus = (orderRequestStatus, isDeleted, id) => {
    if (isDeleted == "1" && orderRequestStatus == "0") {
      return `<button style="background-color:#dc3545;border: 1px solid transparent; height:40px; width:100px; font-size:18px; border-radius: 0.375rem;font-family:"Roboto","Helvetica","Arial",sans-serif; color:white;" id=${id}>Cancelled</button>`;
    } else if (orderRequestStatus == "2") {
      return `<button class="confirmed" style="background-color:#0d6efd; border: 1px solid transparent; height:40px; width:100px; font-size:18px; border-radius: 0.375rem; font-family:"Roboto","Helvetica","Arial",sans-serif; color:white;" id=${id}>Confirmed</button>`;
    } else if (orderRequestStatus == "3") {
      return `<button style="background-color:#198754; border: 1px solid transparent;height:40px; width:100px; font-size:18px; border-radius: 0.375rem; font-family:"Roboto","Helvetica","Arial",sans-serif; color:white;" id=${id}>Delivered</button>`;
    } else {
      return `<button style="background-color:#ffc107; border: 1px solid transparent;height:40px; width:100px; font-size:18px; border-radius: 0.375rem; font-family:"Roboto","Helvetica","Arial",sans-serif; color:white;" id=${id}>Pending</button>`;
    }
  };

  useEffect(() => {
    (async () => {
      const userType = 1;
      const headers = {
        "x-access-token": `${localStorage.getItem("token")}`
      };
      const orderList = await axios.post(
        `${apiBaseURL}/api/v1/admin/paymentsection`,
        { searchBy },
        {
          headers: headers
        }
      );
      if (orderList.data) {
        const response = orderList.data;
        const { data } = response;
        var filterData = data;
        const currentRows = filterData.map((value, index) => {
          return {
            id: value?.id,
            customerName: value?.users?.firstName,
            runnerName: value.hasOwnProperty("runners")
              ? `${value?.runners?.firstName} ${value?.runners?.lastName}`
              : "",
            createdAt: value?.createdAt,
            adminFees: value?.adminFees,
            runnerFees: value?.expectedRunnerFees,
            runnerTip: value?.runnerTip,
            totalFees: value?.totalFees,
            paymentMethod: value?.paymentMethod,
            status: handleStatus(
              value?.orderRequestStatus ? value?.orderRequestStatus : 0,
              value?.isDeleted,
              value?.id
            ),
            refundStatus: value?.refundStatus ? value?.refundStatus : ""
          };
        });
        setRows(currentRows);
      }
    })();
  }, [searchBy]);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => {
    Swal.fire({
      title: `Do you want to delete this customer?`,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: appColor
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const deleteUser = await axios.delete(`${apiBaseURL}/api/v1/admin/deleteuser`, {
              data: {
                id
              }
            });
            if (deleteUser.data) {
              Swal.fire("The customer is deleted now!", "", "success");
              setRows(rows.filter((row) => row.id !== id));
            }
          } catch (error) {
            console.log(error, "error");
          }
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const handleCancelClick = (id) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });
    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow) => {
    const { email, firstName, phoneNumber, id } = newRow;
    if (firstName == "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Name can't be blank!"
      });
    } else if (!/^([a-zA-Z ]){2,30}$/.test(firstName)) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter valid name!"
      });
    } else if (email == "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Email can't be blank!"
      });
    } else if (phoneNumber == "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Mobile number can't be blank!"
      });
    } else if (!/^[0-9]{10}$/.test(phoneNumber)) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter valid mobile number!"
      });
    } else {
      try {
        const formData = new FormData();
        formData.append("profilePic", profilePic);
        formData.append("firstName", firstName);
        formData.append("email", email);
        formData.append("phoneNumber", phoneNumber);
        formData.append("userType", userType);
        formData.append("id", id);
        const updateUser = await axios.post(`${apiBaseURL}/api/v1/admin/updateuser`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        if (updateUser) {
          const updatedRow = { ...newRow, isNew: false };
          setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
          setCallUpdateUser(!calledUpdateUser);
          return updatedRow;
        }
      } catch (error) {
        console.log(error, "error");
        if (error.response.data.message == "Id not provided!!") {
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: "Id not provided!!"
          });
        } else if (error.response.data.message == "No customer found for this id!!!") {
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: "No customer found for this id!!!"
          });
        } else if (error.response.data.message == "This mobile number is already registered!!") {
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: "This mobile number is already registered!!"
          });
        } else if (error.response.data.message == "This email is already registered!!") {
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: "This email is already registered!!"
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: `${error.response.data.message}`
          });
        }
      }
    }
  };

  const handleCancelOrderButton = (id) => {
    Swal.fire({
      title: "Do you want to refund the money to the customer after order cancellation?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`
    })
      .then(async (result) => {
        const headers = {
          "Content-Type": "application/json",
          "x-access-token": `${localStorage.getItem("token")}`
        };
        if (result.isConfirmed) {
          try {
            const refund = 1;
            const cancelOrder = await axios.post(
              `${apiBaseURL}/api/v1/admin/cancelorder`,
              {
                id,
                refund
              },
              {
                headers: headers
              }
            );
            if (cancelOrder.data) {
              Swal.fire(`Order has been canceled successfully!`, "", "success");
              socket.emit("checkCancelAcceptedOrder", {
                orderId: id
              });
              setCalledCancelOrder(!calledCancelOrder);
            }
          } catch (error) {
            console.log(error, "error");
          }
        } else if (result.isDenied) {
          try {
            const refund = 0;
            const cancelOrder = await axios.post(
              `${apiBaseURL}/api/v1/admin/cancelorder`,
              {
                id,
                refund
              },
              {
                headers: headers
              }
            );
            if (cancelOrder.data) {
              Swal.fire(`Order has been canceled successfully!`, "", "success");
              socket.emit("checkCancelAcceptedOrder", {
                orderId: id
              });
              setCalledCancelOrder(!calledCancelOrder);
            }
          } catch (error) {
            console.log(error, "error");
          }
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleFileChange = (e, params) => {
    const file = e.target.files[0];
    if (file) {
      const updatedRow = {
        ...params.row,
        // profilePic: `<img src="${URL.createObjectURL(file)}" />`,?
        profilePic: file
      };
      setProfilePic(updatedRow.profilePic);
      setRows((prevRows) => prevRows.map((row) => (row.id === params.id ? updatedRow : row)));
    }
  };

  const columns = [
    { field: "id", headerName: "Order Id", width: 140 },
    {
      field: "customerName",
      headerName: "Customer Name",
      type: "string",
      width: 150
    },
    {
      field: "runnerName",
      headerName: "Runner Name",
      width: 150,
      type: "string"
    },
    {
      field: "createdAt",
      headerName: "Creation Date and Time",
      width: 220,
      type: "string"
    },
    {
      field: "adminFees",
      headerName: "Admin received",
      width: 150,
      type: "string"
    },
    {
      field: "runnerFees",
      headerName: "Runner received",
      width: 150,
      type: "string"
    },
    {
      field: "runnerTip",
      headerName: "Tip",
      width: 150,
      type: "string"
    },
    {
      field: "totalFees",
      headerName: "Total Amount",
      width: 150,
      type: "string"
    },
    {
      field: "paymentMethod",
      headerName: "Payment Method",
      width: 150,
      type: "string"
    },
    {
      field: "status",
      headerName: "Order Status",
      width: 180,
      editable: false,
      type: "button",
      cellClassName: "actions",
      renderCell: (params) => {
        return (
          <div
            className="actions"
            dangerouslySetInnerHTML={{
              __html: params.value
              // Render the HTML content (image tag)
            }}
            style={{ width: "fit-content" }}
          />
        );
      }
    },
    {
      field: "refundStatus",
      headerName: "Refund",
      width: 150,
      type: "string"
    }
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box sx={{ width: "100%", marginBottom: 2 }}>
        <TextField
          id="outlined-basic"
          label="Search by Customer name, Runner name or Order Id"
          variant="outlined"
          value={searchBy}
          onChange={(event) => {
            setSearchBy(event.target.value);
          }}
          sx={{
            width: "380px"
          }}
        />
      </Box>
      <Box
        sx={{
          height: 675,
          width: "100%",
          "& .actions": {
            color: "text.secondary"
          },
          "& .textPrimary": {
            color: "text.primary"
          }
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } }
          }}
          pageSizeOptions={[10, 20, 30]}
          sx={{
            fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
            fontWeight: 400
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel, addedUser, setAddedUser }
          }}
        />
      </Box>
    </DashboardLayout>
  );
};

export default Payments;
